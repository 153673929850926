
<template>
    <div class="context">
        <div class="title"><span>服务协议</span></div>
        <div class="content">
            <div class="sub-title"><span>1.服务内容</span></div>
            <p>
                1.1 本网站的服务具体内容由剁手记根据实际情况提供，剁手记保留随时变更、中断或终止部分或全部服务的权利。剁手记不承担因业务调整给用户造成的损失。除非本协议另有其它明示规定，增加或强化目前本网站的任何新功能，包括所推出的新产品，均受到本协议之规范。用户了解并同意，本网站服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，剁手记均不承担任何责任。
            </p>
            <p>
                1.2 剁手记在提供服务时，可能会和获取用户在第三方平台如淘宝天猫，支付宝等的授权和认证，剁手记不会利用任何用户授权的信息做和自身业务无关的服务以及出卖给任何第三方做它用，用户可以放心授权使用。同时，用户在第三方平台上的服务以及相关的纠纷请和第三方直接沟通解决，比如商品质量问题，物流问题等，剁手记因为不直接提供商品，所以也不对商品本身产生的问题负责。
            </p>
            <p>
                1.3 用户理解，剁手记仅提供相关的服务，除此之外与相关服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。
            </p>
            <p>
                1.4 剁手记提供的服务内容可能包括：文字、软件、声音、图片、录象、图表等。所有这些内容受著作权权、商标和其它财产所有权法律的保护。
            </p>
            <p>
                1.5 用户只有在获得剁手记或其他相关权利人的授权之后才能使用这些内容，不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
            </p>
            <p>
                1.6 剁手记鼓励用户充分利用剁手记平台自由地张贴和共享自己的信息，但这些内容必须位于公共领域内，同时，用户对于其创作并在剁手记上发布的合法内容依法享有著作权及其相关权利。用户不应通过剁手记张贴他人受知识产权法律等保护的内容。就用户发布的内容，若第三方向剁手记投诉并提交初步证据的，经剁手记判断投诉属实的，剁手记将会删除这些内容，如因用户发布内容给剁手记带来损失的，用户应当负责赔偿。
            </p>
            <p>
                1.7 在剁手记张贴的公开信息： 在本协议中，「本服务公开使用区域」系指一般公众可以使用的区域。 用户同意已就用户于本服务公开使用区域及本服务其它任何公开使用区域张贴之内容，包括照片、文字或影音资料等内容，不可撤销地授予剁手记全球性、永久性的、免许可费及非独家的使用权，剁手记可以为了展示、散布及推广张贴前述内容之特定服务目的，将前述内容复制、修改、改写、改编或出版，对于照片及文字影音资料的上述使用，仅为张贴该照片或文字影音于本服务之目的而为之。 用户同意已就用户于本服务其它公开使用区域张贴的其它内容，授予剁手记免许可费、永久有效、不可撤销、非独家及可完全再授权之权利在全球使用、复制、修改、改写、改编、发行、翻译、创造衍生性著作，及/或将前述内容（部分或全部）加以散布、表演、展示，及/ 或放入利用任何现在已知和未来开发出之形式、媒体和科技之其它著作物当中。 本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2010年修订版《著作权法》确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于您在本网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
            </p>
            
            <div class="sub-title"><span>2.使用规则 </span></div>
            <p>
                2.1 用户注册成功后，剁手记将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
            </p>
            <p>
                2.2 用户须对在剁手记的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则剁手记有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
            </p>
            <p>
                2.3 用户承诺不得以任何方式利用剁手记直接或间接从事违反中国法律、以及社会公德的行为，剁手记有权对违反上述承诺的内容予以删除。
            </p>
            <p>
                 2.4 用户不得利用本网站制作、上载、复制、发布、传播或者转载如下内容：       
                <p1>
                    （1）反对宪法所确定的基本原则的；
                </p1>
                <p1>
                    （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                </p1>
                <p1>
                    （3）损害国家荣誉和利益的； 
                </p1>
                <p1>
                    （4）煽动民族仇恨、民族歧视，破坏民族团结的； 
                </p1>
                 <p1>
                    （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
                </p1>
                 <p1>
                    （6）散布谣言，扰乱社会秩序，破坏社会稳定的； 
                </p1>
                 <p1>
                    （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                </p1>
                <p1>
                    （8）侮辱或者诽谤他人，侵害他人合法权益的；
                </p1>
                <p1>
                    （9）含有法律、行政法规禁止的其他内容的信息。
                </p1>
            </p>
            <p>
                2.5 剁手记有权对用户使用剁手记的情况进行审查和监督，如用户在使用剁手记时违反任何上述规定，剁手记或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用剁手记的权利）以减轻用户不当行为造成的影响。
            </p>

            <div class="sub-title"><span>3.免责声明 </span></div>
            <p>
                3.1 用户将照片、个人信息等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用剁手记服务所存在的风险（包括但不限于受到第三方侵权或对第三方造成侵权）将完全由其自己承担；因其使用剁手记服务而产生的一切后果也由其自己承担，剁手记对此不承担任何责任。
            </p>
            <p>
                3.2 剁手记不担保本网站服务一定能满足用户的要求，也不担保本网站服务不会中断，对本网站服务的及时性、安全性、准确性、真实性、完整性也都不作担保。
            </p>
            <p>
                3.3 对于因不可抗力或剁手记不能控制的原因造成的本网站服务中断或其它缺陷，剁手记不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
            </p>
            <p>
                3.4 根据有关法律法规，剁手记在此郑重提请用户注意，任何经由本网站以上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料（以下简称「内容」），无论系公开还是私下传送，均由内容提供者承担责任。剁手记无法控制经由本网站传送之内容，因此不保证内容的正确性、完整性或品质。在任何情况下，剁手记均不为任何内容负责，包含但不限于任何内容之任何错误或遗漏，以及经由本网站服务以张贴、发送电子邮件或其它方式传送任何内容而衍生之任何损失或损害。但剁手记有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本网站服务的全部或部分，保存有关记录，并向有关机关报告。
            </p>
            <p>
                3.5 剁手记作为网络服务提供者，对非法转载，虚假发布、盗版行为的发生不具备充分的监控能力。剁手记对他人在网站上实施的此类侵权行为不承担法律责任，侵权的法律责任概由本人承担。
            </p>

            <div class="sub-title"><span>4.法律管辖适用及其他 </span></div>
            <p>
                4.1 本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本公司住所地人民法院提起诉讼。
            </p>
            <p>
                4.2 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
            </p>
            <div class="sub-title"><span>5.法律管辖适用及其他 </span></div>
            <p>
                5.1 剁手记将有权随时修改本协议的有关条款，一旦本协议的内容发生变动，剁手记将会通过适当方式在网站上提示。
            </p>
            <p>
                 5.2 如果不同意剁手记对本协议相关条款所做的修改，用户有权停止使用本网站服务。如果用户继续使用本网站服务，则视为用户接受剁手记对本协议相关条款所做的修改。
            </p>
            <p>
            </p>

            <div class="sub-title"><span>剁手记用户服务协议补充条款-2019年8月28日</span></div>
											
            <div class="sub-title"><span>一、服务条款的确认和修改</span></div>

                <p>1．本协议服务条款（以下简称“服务条款”）是由用户（您）与上海始而信息科技有限公司订立，并适用于剁手记提供的所有网络服务。《剁手记》由上海始而信息科技有限公司所运行的剁手记网站（www.duoshouji.com）和《剁手记》移动APP产品（合称：剁手记）构成。</p>
                <p>2．用户在使用剁手记提供的网络服务前有义务仔细阅读本协议。用户在登录程序过程中勾选“我已阅读服务条款”按钮即表示用户完全接受本协议项下的全部条款。本协议即构成对剁手记和用户双方之间均具有法律约束力的正式文件。</p>
                <p>3．用户在此不可撤销地承诺，若其使用剁手记提供的网络服务，将视为用户同意并接受本协议全部条款的约束，此后用户无权以未阅读本协议或对本协议有任何误解为由，主张本协议无效或要求撤销本协议。</p>
                <p>4．服务条款可由剁手记随时更新，且无须另行通知。服务条款一旦发生变更，剁手记将在剁手记网站上公布修改内容。修改后的服务条款一旦在剁手记网站上公布即有效代替原来的服务条款。您可随时打开网站查阅最新版服务条款。用户如果不同意修改后的本协议的任何条款，可以放弃访问剁手记相关网页或主动卸载剁手记客户端；如果用户选择继续接受剁手记提供的服务，则视为用户已经完全接受本协议及其修改。</p>
            <div class="sub-title"><span>二、剁手记服务内容</span></div>

                <p>1．剁手记会员（即剁手记的已登录用户，以下简称会员）通过剁手记购买任意商品和参加剁手记及其合作组织所举行的各种活动可以获得红包返利奖励。</p>
                <p>2．返利将以现金或者返利等形式在经过剁手记审核后发放给用户，剁手记拥有对返利形式的决定权和解释权，改变形式之前无需通过会员同意。</p>
                <p>3．会员通过剁手记购买淘宝和天猫的商品应当获得的返利数量由剁手记和卖家协商确定，卖家按照淘宝联盟和支付宝的要求通过剁手记提供的服务返利给剁手记会员。会员参加剁手记及其合作组织举办的其他各种活动获得的返利的规则由各项具体活动详细规定，请您密切注意活动页面。</p>
                <p>4．会员除在剁手记指定的频道或者平台上进行相关奖励的交易涉及到返利的流转外，返利不能以买卖、赠与等其他任何形式转让予他人。</p>
                <p>5．正常情况下，剁手记会在确认了会员获得的返利数量后，在30个工作日内安排转账到会员的支付宝账户（遇到节假日情况顺延），如因特殊原因（包括但不限于用户支付宝提供错误、订单存在维权风险等），返利到账时间可能会延迟。</p>
                <p>6．通过剁手记及其关联公司或合作组织提供的剁手记服务和其它服务，会员可在业务规则允许范围内在剁手记上发布交易信息、查询商品和服务信息、达成交易意向并进行网站内部交易、对其他会员进行评价、参加剁手记组织的活动以及使用其它信息服务及技术服务。</p>
                <p>7．您在剁手记的交易过程中与其他会员发生交易纠纷时，一旦您或其它会员任一方或双方共同提交剁手记要求调处，则剁手记有权根据单方判断做出调处决定，您了解并同意接受剁手记的判断和调处决定。该决定将对您的纠纷具有约束力。</p>
                <p>8．您了解并同意，剁手记有权应政府部门（包括司法及行政部门）的正当合法的要求，向其提供您在剁手记填写的注册信息和交易纪录等必要信息。如您涉嫌侵犯他人知识产权或者其他合法权益，则剁手记亦有权在初步判断涉嫌侵权等违法行为存在的情况下，向权利人提供您必要的身份信息。除非法律法规或相关政府部门另有要求，剁手记将在前述信息披露情况发生后及时向您发出书面通知。 </p>
            <div class="sub-title"><span>三、用户行为规范</span></div>

                <p>在使用剁手记服务过程中，您承诺遵守下列使用规范：</p>
                <p>1．用户单独承担发布内容的责任，并承诺在使用剁手记的服务时必须符合中国有关法规。</p>
                <p>2．用户不得利用剁手记的服务制作、复制、发布、传播以下信息：</p>
                <p>1）对宪法确定的基本原则的；</p>
                <p>2）危害国家统一、主权和领土完整的；</p>
                <p>3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
                <p>4）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</p>
                <p>5）宣扬邪教、迷信的；</p>
                <p>6）扰乱社会秩序，破坏社会稳定的；</p>
                <p>7）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</p>
                <p>8）侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；</p>
                <p>9）危害社会公德，损害民族优秀文化传统的；</p>
                <p>10）有关法律、行政法规和国家规定禁止的其他内容。</p>
                <p>3．基于网络服务的特性及重要性，会员同意：</p>
                <p>1）提供详尽、准确的个人资料。</p>
                <p>2）不断更新注册资料，符合及时、详尽、准确的要求。</p>
                <p>3）自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置。</p>
                <p>4）自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</p>
                <p>4．用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知剁手记。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，剁手记不承担任何责任。</p>
                <p>5．用户同意剁手记有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在剁手记产品的任何页面上投放广告），并且，用户同意接受剁手记通过短信、电子邮件或其他方式向用户发送产品促销或其他相关商业信息。</p>
                <p>6．用户同意授权剁手记提供用户淘宝收藏夹和购物车功能。</p>
                <p>7．对于用户通过剁手记网络服务（包括但不限于论坛、BBS、评论、个人微博）上传到剁手记上可公开获取区域的任何内容，用户同意剁手记在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</p>
                <p>8．用户在使用剁手记网络服务过程中或者通过剁手记服务所传送的任何内容并不反映上海始而信息科技有限公司的观点或政策，上海始而信息科技有限公司对此不承担任何责任。</p>
                <p>9．因用户支付宝异常导致返利无法到帐，用户须在该订单确认收货后90天内主动联系剁手记客服处理，逾期不予处理。</p>
                <p>10．用户不得利用剁手记进行任何不利于剁手记的行为。当剁手记系统检测到用户有异常行为，或者接到关于用户恶意套取返利的举报，剁手记有权延长发放时间（最迟180天）或冻结返利。用户异常行为包括但不局限于恶意刷单、刷返利补贴、套取返利、违规操作等剁手记风控部门认定的异常操作和异常返利行为。</p>
                <p>11．剁手记有权对用户使用剁手记服务的情况进行审查和监督（包括但不限于对用户存储在剁手记的内容进行审核），如用户在使用剁手记网络服务时违反任何上述规定，剁手记有权要求用户改正或直接采取一切必要的措施取消会员账户应该享有的会员福利（返利、红包、积分、活动奖励等剁手记提供给用户的福利）（包括但不限于更改或删除用户张贴的内容等、冻结返利或终止用户使用剁手记网络服务的权利）以减轻用户不当行为造成的影响。</p>
            <div class="sub-title"><span>四、隐私权政策</span></div>

                <p>1．剁手记对希望成为会员的用户没有任何限制，但18岁以下的用户使用剁手记服务必须取得监护人的同意；</p>
                <p>2．一个帐号仅限一个会员使用，会员必须向剁手记提供真实确实的信息，对于由于资料提供不正确导致汇款无法收到等后果，剁手记不承担责任；</p>
                <p>3．会员资料修改后必须及时通知剁手记做出相应变更；</p>
                <p>4．剁手记及其关联公司承诺不向其它第三方机构透露会员涉及隐私的信息；</p>
                <p>5．会员必须遵守剁手记（及合作组织）的使用条款及隐私政策。</p>
            <div class="sub-title"><span>五、协议终止</span></div>

                <p>1．您同意，剁手记有权依据本协议决定中止、终止向您提供部分或全部剁手记平台服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任，但本协议或法律法规另有明确要求的除外。</p>
                <p>2．出现以下情况时，剁手记有权直接以注销账户的方式终止本协议：</p>
                <p>1）会员超过两年无登陆记录；</p>
                <p>2）剁手记终止向您提供服务后，您涉嫌再一次直接或间接或以他人名义注册为剁手记用户的；</p>
                <p>3）您提供的电子邮箱不存在或无法接收电子邮件，且没有其他方式可以与您进行联系，或剁手记以其它联系方式通知您更改电子邮件信息，而您在剁手记通知后七个工作日内仍未更改为有效的电子邮箱的；</p>
                <p>4）您注册信息中的主要内容不真实或不准确或不及时或不完整；</p>
                <p>5）剁手记系统检测您的账户有恶意刷单、刷返利补贴、套取返利、违规操作，且无法向剁手记提供合理解释说明的；</p>
                <p>6）本协议（含规则）变更时，您明示并通知剁手记不愿接受新的服务协议的；</p>
                <p>7）其它剁手记认为应当终止服务的情况。</p>
                <p>3．您有权向剁手记要求注销您的账户，经剁手记审核同意的，剁手记注销（永久冻结）您的账户，届时，您与剁手记基于本协议的合同关系即终止。您的账户被注销（永久冻结）后，剁手记没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。</p>
                <p>4．您同意，您与剁手记的合同关系终止后，剁手记及其关联公司或者其合作组织仍享有下列权利：</p>
                <p>1）继续保存并使用您的注册、登记信息、数据及您使用剁手记服务期间的所有交易数据。   </p>
                <p>2）您在使用剁手记服务期间存在违法行为或违反本协议和/或规则的行为的，剁手记仍可依据本协议向您主张权利。</p>
                <p>5．剁手记中止或终止向您提供服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保剁手记免于因此产生任何损失或承担任何费用：</p>
                <p>1）您在服务中止或终止之前已经上传至剁手记的物品尚未交易的，剁手记有权在中止或终止服务的同时删除此项物品的相关信息；</p>
                <p>2）您在服务中止或终止之前已经与其他会员达成买卖合同，但合同尚未实际履行的，剁手记有权删除该买卖合同及其交易物品的相关信息；</p>
                <p>3）您在服务中止或终止之前已经与其他会员达成买卖合同且已部分履行的，剁手记可以不删除该项交易，但剁手记有权在中止或终止服务的同时将相关情形通知您的交易对方。</p>
                <p>4）您在服务中止或终止之前通过剁手记进行恶意刷单、刷返利补贴、套取返利、违规操作等给剁手记带来经济损失的，剁手记有权向用户追回利益损失。</p>
            <div class="sub-title"><span>六、法律适用、管辖与争议解决</span></div>

                <p>1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。</p>
                <p>2、因本协议产生之争议，应依照中华人民共和国法律予以处理。双方对于争议协商不成的，应当提交上海始而信息科技有限公司营业地所在的人民法院诉讼解决。</p>
            <div class="sub-title"><span>七、其他规定</span></div>

                <p>1．上海始而信息科技有限公司郑重提醒用户注意本协议中免除上海始而信息科技有限公司责任和加重用户义务的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。以上各项条款内容的最终解释权及修改权归上海始而信息科技有限公司所有。</p>
                <p>2．本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</p>
                <p>3．如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
                <p>4．本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
                <p>5．本协议的版权由上海始而信息科技有限公司所有，上海始而信息科技有限公司保留一切解释和修改权利。</p>
        </div>
    </div>

</template>

<script>
export default {

}
</script>
<style>
body {
    width: 100%;
}
</style>
<style scoped>
.context {
    font-size: 16px;
    color: #666;
    padding: 10px
}
.title {
    font-size: 28px;
    color: #333;
    font-weight: 500;
    margin-bottom: 20px;
}
.sub-title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
}
p {
    margin-bottom: 10px;
    text-align: left;
}
p1 {
    display: inherit;
    text-align: left;
    margin: 5px 0;
}
</style>